.font_h1 {
    font-size: 2rem;
    font-family: 'Noto Sans', sans-serif;
    font-weight: lighter;
}

.font_h2 {
    font-size: 1.5rem;
    font-family: 'Noto Sans', sans-serif;
    font-weight: 600;
}

.font_h3 {
    font-size: 1.17rem;
    font-family: 'Noto Sans', sans-serif;
    font-weight: normal;
}

.font_h4 {
    font-size: 1.25rem;
    font-family: 'Noto Sans', sans-serif;
    font-weight: normal;
}

.font_h5 {
    font-size: 0.83rem;
    font-family: 'Noto Sans', sans-serif;
    font-weight: normal;
}

.font_h6 {
    font-size: 0.625rem;
    font-family: 'Noto Sans', sans-serif;
}

.font_body {
    font-size: 1.6rem;
    font-family: 'Noto Sans', sans-serif;
}

.font_subtitle {
    font-size: 1.4rem;
    font-family: 'Noto Sans', sans-serif;
}

.font_button {
    font-size: 0.75rem;
    font-family: 'Noto Sans', sans-serif;
}

.font_caption {
    font-size: 1.2rem;
    font-family: 'Noto Sans', sans-serif;
}

.font_overline {
    font-size: 1rem;
    font-family: 'Noto Sans', sans-serif;
}

.color_primary {
    background: linear-gradient(to top, #33cc66, #ffff33);
    color: #000;
}

.color_secondary {
    background: #fff;
    color: #000;
}

.color_button {
    background: #009d00;
    color: #fff;
}

.color_title {
    background: transparent;
    color: #000000;
}

.page_color {
    background: #000;
    color: #fff;
}

.color_active {
    background: transparent;
    color: #009d00;
}

.contact_front_d {
    background: linear-gradient(to top, #33cc66, #ffff33);
    color: #000;
    border-radius: 5px 5px 5px 5px;
}

.contact_back_d .list-group {
    /* border: 1px solid #009d00; */
}

.contact_back_d .contact_back_d_header {
    background: linear-gradient(to top, #33cc66, #ffff33);
    ;
    color: #000;
    border-radius: 5px 0 0 0;
}

.contact_back_d .contact_back_d_item {
    background: #f2f2f2;
    color: #000;
    border-top: 0px solid;
    border-bottom: 1px dashed;
    border-left: 0px solid;
    border-right: 0px solid;

}

.contact_back_d .contact_back_d_item .contact_back_content span {
    color: #808080;
    word-break: break-all;
}

.contact_back_d.card>.list-group:last-child {
    border-radius: 0 0 0 5px;
}

.announcement_body {
    color: #000;
}

.announcement_header {
    /* border-bottom: 1px solid #009d00; */
    border-bottom: 0;
    background: transparent;
}

.announcement_header img {
    filter: drop-shadow(1px 2px 0px black);
    width: auto;
    height: 3.5rem;
}

.announcement_header_title {
    color: #009d00;
}

.announcement_header::before {
    background: #009d00;
}

/* .announcement_header .btn-close{
    background-image: var(--btn-close-bg-white);
    opacity: 1;
} */

.announcement_btnClose {
    background: #009d00;
    color: #fff;
    border-radius: 5px 5px 5px 5px;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
}

.header_topBar {
    background: #fff;
    color: #000;
    filter: drop-shadow(0 2px 3px rgb(0, 0, 0, .1));
    /* box-shadow: 1px 0px 3px 0px rgba(255, 255, 255, .3); */
    border-bottom: 1px solid #009d00;
}

.header_menu {
    color: rgba(0,0,0,.3);
    background: linear-gradient(to top, #33cc66, #ffff33);
    box-shadow: inset 0px 0px 2px 0px rgb(0 0 0 / 50%);
}

.header_menu .header_menu_page .header_menu_content.active {
    background: transparent;
    color: #000;
    /* border-bottom: 3px solid #009d00; */
}

.btn_banking .banking_dropdown,
.btn_claim .claim_dropdown {
    background: #fff;
}

.banking_dropdown a li,
.claim_dropdown li {
    background: #f2f2f2;
    color: #999;
}

.banking_dropdown a.active li,
.claim_dropdown a.active li {
    background: linear-gradient(to top, #33cc66, #ffff33);
    color: #000;
}

.header_btnLogin {
    background: #009d00;
    color: #fff;
    border-radius: 5px 5px 5px 5px;
    /* border-top: 1px solid #009d00;
    border-bottom: 1px solid #009d00;
    border-left: 1px solid #009d00;
    border-right: 1px solid #009d00; */
}

.header_btnRegister {
    background: linear-gradient(to top, #a20000, #ff0000);
    color: #ffffff;
    border-radius: 5px 5px 5px 5px;
}

.header_btnLogout {
    background: linear-gradient(to top, #a20000, #ff0000);
    color: #ffffff;
    border-radius: 5px 5px 5px 5px;
}

.header_btnRestore {
    background: linear-gradient(to top, #a20000, #ff0000);
    color: #fff;
    border-radius: 5px 5px 5px 5px;
}

.header_btnBanking {
    background: #009d00;
    color: #fff;
    border: 0;
    border-radius: 5px 5px 5px 5px;
}

.header_btnPromoClaim {
    background: #009d00;
    color: #fff;
    border-radius: 5px 5px 5px 5px;
    /* border: 1px solid #009d00 */
}

.header_topBar+.header_banking_info {
    background: #fff;
    color: #000;
    box-shadow: 0px 0px 5px 0px rgb(231, 255, 0, .5);
    border-bottom: 1px solid #009d00;
}

.header_sideBar_topBar {
    background: #f2f2f2;
    color: #fff;
    filter: drop-shadow(-3px 0px 2px rgba(255, 255, 255, .3));
    border-bottom: 1px solid #009d00;
}

.navbar-toggler {
    background: transparent;
    /* color: #fff; */
}

.header_sideBar .header_sideBar_body {
    background: #fff;
    color: #000;
}

.header_sideBar_menu .header_menu_content,
.header_sideBar_menu summary.header_menu_content+.sidebar_language_selection p:last-child {
    background: transparent;
    color: #666666;
    border-bottom: 1px solid #666666;
    margin: 0 .85rem;
}

.header_sideBar_menu .header_menu_content.active {
    background: transparent;
    color: #000;
    border-bottom: 1px solid #666666;
}

.header_banking_info .header_banking_menu_list {
    color: #999;
}

.header_banking_info .header_banking_menu_list .active {
    color: #000;
    background: linear-gradient(to top, #33cc66, #ffff33);
    border-radius: 5px;
}

.header_topBar_wrap .header_banking_info .header_balance {
    background: #009d00;
    color: #fff;
}

/* .header_banking_info .header_balance_icon {
    color: #000;
} */

#promoClaimModal label {
    color: #009d00;
}

#promoClaimModal .filterTab .filterTab_selection.active {
    background: #fff;
    color: #000;
}

.footer {
    background: #f2f2f2;
    color: #000;
    box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
    /* border-top: 1px solid #009d00; */
}

.footer_menu_wrap {
    border-bottom: 1px dashed #000;
}

.footer_payment_method .footer_info_img li:first-of-type img {
    /* filter: brightness(0) invert(1); */
}

.btnCancel {
    background: #e6e6e6;
    color: #000;
    border: 0;
    border-radius: 5px 5px 5px 5px;
}

.modal .confirm_button {
    background: #009d00;
    color: #fff;
    border-radius: .375rem;
    padding: .375rem .75rem;
}

.modal .cancel_button {
    background: #e6e6e6;
    color: #000;
    border: 0;
    border-radius: .375rem;
    padding: .375rem .75rem;
}

.tab_pills_color.nav-pills .nav-link:hover {
    color: #009d00;
}

.footer_help a:hover,
.header_banking_menu_list a:hover,
.header_menu_right .header_menu_page:hover,
.walletBar_selection:hover,
.banking_dropdown a li:hover,
.header_menu_page .header_text:hover,
.claim_dropdown li:hover {
    color: #009d00;
}

.footer_help a:hover {
    color: #fff;
}

.header_menu_page .header_text:hover {
    color: #009d00;
}

.banking_dropdown a li:hover,
.claim_dropdown li:hover {
    background: linear-gradient(to top, #33cc66, #ffff33);
    color: #000;
}

.scrollToTopButton {
    background: linear-gradient(to top, #33cc66, #ffff33);
    color: #000;
    border-radius: 50%;
    padding: .375rem .75rem;
}

.swiper .swiper-pagination-bullet {
    background: #009d00;
}

.swiper-pagination-bullet-active {
    background: #009d00;

}

.alertMessage_content svg {
    color: #009d00;
}

/* .form_required_input,
.register_error_msg,
.forgotPassword_error_msg,
.forgotUsername_error_msg,
.error_msg,
.form_required,
.displayQR_row label,
.input_error_msg {
    color: #fff;
} */

.login_form_reminder_title,
.register_form_reminder_title,
.forgotPassword_form_reminder_title,
.forgotUsername_form_reminder_title {
    color: red;
    font-weight: bold;
}

.forgot_content .forgot_content_username,
.forgot_content .forgot_content_password {
    color: red;
}

.btnCancelPromotion {
    background: #009d00;
    color: #fff;
    border: 0;
}

.btnAfterVerify {
    background: #009d00;
    color: #fff;
    border: 0;
    /* border: 1px solid #009d00; */
}

.announcement_header_title span {
    margin-bottom: .25rem;
}

.section_title {
    color: #000;
}

.header_topBar_wrap .header_bottom_row .header_balance.coupon_balance {
    background: #ccc;
    color: #000;
    outline: 1px solid #87ca4e;
    border-radius: 5px 5px 5px 5px;
}

#gameWalletModal .game_wallet_dropdown table tbody tr {
    color: #000;
    background: #e6e6e6;
}

#promoClaimModal .filterTab {
    background: linear-gradient(to top, #33cc66, #ffff33);
    color: #000;
    border-radius: 10px;
}

#promoClaimModal .filterTab .filterTab_selection {
    border: 0;
    border-radius: 10px;
}

.section_details {
    color: #4d4d4d;
}

.card_feedback .feedback_rating_wrap .feedback_rating img {
    margin-right: .25rem;
}

.header_banking_info .header_banking_menu_list span.active {
    background: transparent;
}

.header_logo img {
    height: 3.5rem;
  }
  .header_d .header_logo img {
    height: 4.5rem;
  }

.header_sideBar.offcanvas {
    background: #fff;
}

.gameWallet_accordion .accordion-button {
    background: #d1e9cd;
    color: #000;
}

.gameWallet_accordion .gameWallet_accordion_icon {
    background: linear-gradient(to top, #a20000, #ff0000);

}

.game_wallet_dropdown .main_wallet {
    background: linear-gradient(to top, #33cc66, #ffff33);
    color: #000;
}

.game_wallet_dropdown table tbody tr {
    background: #f2f2f2;
    /* outline: 1px solid #87ca4e; */
    outline-offset: -1px;
}




.section_title img {
    max-width: unset;
    height: 3rem;
    margin-right: .25rem;
}

.header_top_row,
.header_bottom_row {
    color: #000;
}


.contact_back_d.card {
    /* width: 12rem; */
}

.btn_gamewallet .game_wallet_dropdown,.selectProviderContainer .game_wallet_dropdown {
    background: #fff;
    filter: drop-shadow(2px 3px 3px rgba(0, 0, 0, .3));

}

.btn_gamewallet .game_wallet_dropdown:after,.selectProviderContainer .game_wallet_dropdown:after {
    border-bottom: 8px solid #fff;

}

.header_point span:nth-child(1){
    color: #009d00;
}

.header_banking_info_left .header_balance_currency span:nth-child(2){
    color: #009d00;

}

.header_sideBar .btn-close {
    color: #000;
  }

  .header_d .header_topBar {
    background: url('https://storage.googleapis.com/s9asia-images.imbaweb.com/background/bg_header.png');
    background-attachment: fixed;
    background-position-x: -6rem;
    background-size: 100% 6rem;
}


  .header_m .header_topBar {
    background: url('https://storage.googleapis.com/s9asia-images.imbaweb.com/background/bg_header_m.png');
    background-repeat: no-repeat;
    background-size: cover;
}


