.speakerAnnouncement_body {
    background: #f2f2f2;
    color: #000;
    padding: .35rem 0;
}

.speakerAnnouncement_container {
    width: 97%;
}

.speakerAnnouncement_iconContainer img {
    width: 3.75rem;
    height: 1.75rem;
}

.speakerAnnouncement_content {
    font-size: 1rem;
}

.main_game {
    background: transparent;
    padding: 1rem 0 2rem;
}

.main_gameSelection .main_gameSelection_menu {
    background: #f2f2f2;
    color: #666666;
    border-radius: 10px;
    margin: 5px;
    border: 1px solid #009d00;
    filter: drop-shadow(rgb(231, 255, 0, .35) 2px 2px 2px);
}

.main_gameSelection .main_gameSelection_menu.active {
    background: linear-gradient(to top, #33cc66, #ffff33);
    color: #000;
    border: 0;
}

.main_game_container {
    background: #f2f2f2;
    color: #000;
    border-radius: 10px;
    padding: 0 .5rem;
    /* box-shadow: inset -4.5px 0px 12px 1px rgba(0, 0, 0, 0.25); */
}

.main_gameSelection .main_gameSelection_menu .main_gameSelection_image {
    margin: 0rem;
}

.main_gameSelection_vertical .main_gameSelection_menu img {

    max-height: 4.5rem;

}

#Section_gameType .Section_gameType_body {
    background: transparent;
    padding-top: 2.5rem;
}

#Section_gameType .Section_gameType_body .section_title {
    font-weight: normal;
}

#Section_gameType .card_image_wrap {
    background: rgba(0, 0, 0, 0);
    border-radius: 5px 5px 5px 5px;
    filter: drop-shadow(rgb(231, 255, 0, .25)2px 5px 3px);
}

#Section_gameType .content_container {
    background: rgba(0, 0, 0, 0);
    border-radius: 0px 0px 5px 5px;
}


#Section_mainPage_promotion .Section_mainPage_promotion_body {
    background: transparent;
    color: #fff;
    padding-top: 1.5rem;
}

#Section_mainPage_promotion .Section_mainPage_promotion_body .section_title {
    font-weight: 600;
}

#Section_mainPage_promotion .card_image_wrap {
    background: transparent;
    border-radius: 5px;
    filter: drop-shadow(rgb(0, 0, 0) 2px 3px 3px);
}

#Section_Service {
    background: #00013e;
    color: #fff;
    padding: 1.5rem 0 3rem;
}

#Section_Service .section_title {
    font-weight: 600;
}

.contactUs_body {
    background: transparent;
    color: #fff;
}

#contactUs_title.pageTitle {
    background: transparent;
    color: #000;
    /* padding-top: 0; */
    padding-bottom: 2.5rem;
}

.contact_card.card {
    background: #f2f2f2;
    color: #000;
    border-radius: 0.8rem;
    border-top: 0;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    margin: 10px;
    padding: 1rem;
    box-shadow: none;
}

#contactUs_title_m {
    background: transparent;
    color: #000;
    padding: 2rem 1.25rem 1rem;
}

.contact_container_m .contact_card.card {
    background: transparent;
    color: #000;
    border-radius: 0;
    border-top: 0;
    border-bottom: 1px dashed #808080;
    border-left: 0;
    border-right: 0;
    margin: 0;
    padding: 0 1rem;
    box-shadow: none;
}

#promotion_title {
    background: transparent;
    color: #000;
}

#Section_promoContainer .Section_promoContainer_body {
    background: transparent;
}

#Section_promoContainer .card_container {
    border-bottom: 1px dashed #333;
    padding: 0.8rem 1.5rem;
}

#Section_promoContainer .card_image_wrap {
    border-radius: 5px;
    filter: drop-shadow(rgb(0, 0, 0) 2px 3px 3px);
}

#Section_promoContainer .content_container {
    background: rgba(0, 0, 0, 0);
}

#Section_promoContainer .card_content_title {
    color: #009d00;
}

#Section_promoContainer .button_action1 {
    background: #009d00;
    color: #fff;
    border: 0;
    border-radius: 5px 5px 5px 5px;
}

#Section_promoContainer .button_action2 {
    background: linear-gradient(to top, #a20000, #ff0000);
    color: rgba(255, 255, 255, 1);
    border: 0;
    border-radius: 5px 5px 5px 5px;
}

#Section_promoContainer .filterTab {
    background: linear-gradient(to top, #33cc66, #ffff33);
    border-radius: 5px;
    margin-top: 2rem;
    padding: 0.5rem;
    color: rgba(0, 0, 0, .3);
}

#Section_promoContainer .filterTab .filterTab_selection {
    padding: 0.5rem 1rem;
}

#Section_promoContainer .filterTab .filterTab_selection.active {
    background: #fff;
    color: #000;
    border-radius: 5px;
}

#Section_promoContainer .promotionCode_part {
    border: 1px solid #33cc66;
    border-radius: 5px;
}

#Section_promoContainer .promotionCode_part label {
    background: transparent;
    border-radius: 5px 0 0 5px;
    color: #000;
}

#Section_promoContainer .promotionCode_part .promotionCode_content {
    background: #33cc66;
    border-radius: 0 2px 3px 0;
    color: #fff;
}

#Section_promoContainer .promotionTag_part label {
    background: #e6e6e6;
    color: #000;
    border-radius: 5px 5px 5px 5px;
    margin-right: .5rem;
    padding: .25rem .5rem;
}

.type_promotion .card_content_desc_container {
    color: #000;
}

.help_body {
    background: transparent;
    padding: 0 0 3rem;
}

#help_title {
    background: transparent;
    color: #000;
}

.help_accordion {
    padding: 2rem 1.5rem;
}

.help_accordion .accordion-item .accordion-button {
    background: #f2f2f2;
    border-radius: 5px 5px 5px 5px;
    color: #999;
    border: 0;
    font-weight: 600;
}

.help_accordion .accordion-item .accordion-button:not(.collapsed) {
    background: linear-gradient(to top, #33cc66, #ffff33);
    border: 0 solid;
    color: #000;
}

.help_accordion .accordion-item .accordion-collapse {
    border: 0;
    border-radius: 0 0 5px 5px;
    border-top: 0;
}

.help_accordion .accordion-item .accordion-body {
    background: #fff;
    color: #000;
    border-radius: 0 0 5px 5px;
    /* box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, .5); */
    border: 1px solid #009d00;
}

.login_body {
    background: transparent;
    color: #000;
}

#login_title_d {
    background: transparent;
    color: #009d00;
    font-weight: bold;
}

#login_title_m {
    background: transparent;
    color: #009d00;
    font-weight: normal;
}

.login_container {
    background: #fff;
    color: #000;
    border-radius: 15px 15px 15px 15px;
    filter: drop-shadow(rgb(231, 255, 0, .35) 0px 1px 3px);
    border: 1px solid #009d00;
}

.login_form .form-label {
    color: #000;
}

.login_btnLogin {
    background: #009d00;
    color: #fff;
    border-radius: 5px 5px 5px 5px;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
}

.register_body {
    background: transparent;
    color: #000;
}

#register_title_d {
    background: transparent;
    color: #009d00;
    font-weight: bold;
}

#register_title_m {
    background: transparent;
    color: #009d00;
    font-weight: normal;
}

.register_container {
    background: #fff;
    color: #000;
    border-radius: 15px 15px 15px 15px;
    filter: drop-shadow(rgb(231, 255, 0, .35) 0px 1px 3px);
    border: 1px solid #009d00;
}

.register_stepper {
    filter: drop-shadow(2px 3px 2px rgba(0, 0, 0, .25));
}

.register_stepper button {
    background: #e6e6e6;
    color: #999;
}

.register_stepper .register_stepContainer.active button {
    background: linear-gradient(to top, #33cc66, #ffff33);
    color: #000;
}

.register_stepContainer:not(:first-child):before {
    background: #e6e6e6;
}

.register_stepContainer.active:not(:first-child)::before {
    background: #9DE25A;
}

.register_form .form-label {
    color: #000;
}

.register_btnNext {
    background: #009d00;
    color: #fff;
    border-radius: 5px 5px 5px 5px;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
}

.register_btnBack {
    background: #e6e6e6;
    color: #000;
    border: 0;
    border-radius: 5px 5px 5px 5px;
}

.register_btnDepositNow {
    background: linear-gradient(to top, #a20000, #ff0000);
    color: #fff;
    border-radius: 5px;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
}

.register_btnSend {
    background: #009d00;
    color: #fff;
    border-radius: 5px 5px 5px 5px;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
}

.forgotPassword_body {
    background: transparent;
    color: #000;
}

#forgotPassword_title_d {
    background: transparent;
    color: #009d00;
    font-weight: bold;
}

#forgotPassword_title_m {
    background: transparent;
    color: #009d00;
    font-weight: normal;
}

.forgotPassword_container {
    background: #fff;
    color: #000;
    border-radius: 15px 15px 15px 15px;
    filter: drop-shadow(rgb(231, 255, 0, .35) 0px 1px 3px);
    border: 1px solid #009d00;
}

.forgotPassword_form .form-label {
    color: #000;
}

.forgotPassword_btnNext {
    background: #009d00;
    color: #fff;
    border-radius: 5px 5px 5px 5px;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
}

.forgotPassword_btnBack {
    background: transparent;
    color: #000;
    border: 1px solid #009d00;
    border-radius: 5px 5px 5px 5px;
}

.forgotPassword_btnSubmit {
    background: #009d00;
    color: #fff;
    border-radius: 5px 5px 5px 5px;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
}

.forgotPassword_btnSend {
    background: #009d00;
    color: #fff;
    border-radius: 5px 5px 5px 5px;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
}

.forgotUsername_body {
    background: transparent;
    color: #000;
}

#forgotUsername_title_d {
    background: transparent;
    color: #009d00;
    font-weight: bold;
}

#forgotUsername_title_m {
    background: transparent;
    color: #009d00;
    font-weight: normal;
}

.forgotUsername_container {
    background: #fff;
    color: #000;
    border-radius: 15px 15px 15px 15px;
    filter: drop-shadow(rgb(231, 255, 0, .35) 0px 1px 3px);
    border: 1px solid #009d00;
}

.forgotUsername_form .form-label {
    color: #000;
}

.forgotUsername_btnSubmit {
    background: #009d00;
    color: #00013e;
    border-radius: 5px 5px 5px 5px;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
}

.forgotUsername_btnSend {
    background: #009d00;
    color: #00013e;
    border-radius: 5px 5px 5px 5px;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
}

.myProfile_body {
    background: transparent;
    color: #000;
}

#myProfile_title {
    background: transparent;
    color: #000;
}

.myProfile_mySetting_row {
    background: transparent;
    color: #000;
    border: 1px solid #009d00;
}

.myProfile_myAccount_row {
    background: transparent;
    color: #000;
    border: 1px solid #009d00;
}

.myProfile_myAccount_row svg {
    color: #000;
}

.slots_body {
    background: transparent;
}

#slot_title {
    background: transparent;
    color: #000;
    font-weight: bold;
}

.slots_body .title_container a+div select {
    border-radius: 20px;
}

.gameProviderTab_container {
    background: #e6e6e6;
    border-radius: 5px 5px 5px 5px;
    /* border: 1px solid #666; */
    /* filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.25)); */
}

.gameProviderTab .gameProviderTab_selection {
    background: #fff;
    border: 1px solid #009d00;
    border-radius: 10px 10px 10px 10px;
}

.gameProviderTab .gameProviderTab_selection img {
    filter: opacity(0.5) grayscale(1);
}

.gameProviderTab .gameProviderTab_selection.active {
    filter: none;
    background: #fff;
    color: #000;
}

.gameProviderTab .gameProviderTab_selection.active img {
    filter: none;
}


.gameProviderTab_container button {
    background: transparent;
    color: #000;
}

.search_container input {
    border-radius: .5rem;
}

.slotTypeTab {
    background: linear-gradient(to top, #33cc66, #ffff33);
    border-radius: 5px 5px 5px 5px;
    color: rgba(0, 0, 0, .3);
    /* filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.25)); */

}

.slotTypeTab .slotTypeTab_selection.active {
    background: #fff;
    color: #000;
    border-radius: 5px 5px 5px 5px;
}

#slotGameContainer .button_action1 {
    background: #009d00;
    color: #fff;
    border-radius: 5px 5px 5px 5px;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
}

#slotGameContainer .card_image_wrap {
    border-radius: 5px;
    filter: drop-shadow(rgb(231, 255, 0, .5)2px 2px 2px);
}

#slotGameContainer .content_container {
    background: rgba(255, 255, 255, 0);
    color: #000;
    border-radius: 0px 0px 5px 5px;
}

.mslot_container {
    margin: 4rem 10rem;
}

.mslot_container_m {
    margin: 0 5rem 1rem;
}

.mslot_container .mslot_wrap {
    background: #fff;
    color: #000;
    border-radius: 0.5rem;
    /* box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, .5); */
    box-shadow: none;
    padding: 3.5rem 2rem;
    border: 1px solid #009d00;
    filter: drop-shadow(rgb(231, 255, 0, .35)2px 2px 2px);
}

.mslot_container .mslot_title {
    border-bottom: 1px solid #009d00;
}

.mslot_container_m .mslot_wrap {
    background: transparent;
    color: #000;
    border-radius: 0;
    box-shadow: none;
    padding: 1.5rem;
    border: 0;
    filter: none;
}

.mslot_userInfo label {
    color: #000;
}

.mslot_container .mslot_userInfo input {
    border-radius: 0.5rem;
}

#mslotGameContainer .mslotGameContainer_body {
    background: #00013e;
    padding-top: 1.5rem;
}

#Section_1675926801290 .card_image_wrap {
    filter: drop-shadow(rgb(0, 0, 0) 2px 3px 3px);
}

#mslotGameContainer .content_container {
    background: rgba(231, 231, 231, 0);
    color: #fff;
}

#casinoGameContainer .casinoGameContainer_body {
    background: transparent;
    padding-top: 1.5rem;
}

#casinoGameContainer .card_image_wrap {
    filter: drop-shadow(rgb(0, 0, 0) 2px 3px 3px);
}

#casinoGameContainer .content_container {
    background: rgba(231, 231, 231, 0);
    color: #000;
}

#sportGameContainer .sportGameContainer_body {
    background: transparent;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
    padding-top: 1.5rem;
}

#sportGameContainer .card_image_wrap {
    filter: drop-shadow(rgb(0, 0, 0) 2px 3px 3px);
}

#sportGameContainer .content_container {
    background: rgba(231, 231, 231, 0);
    color: #000;
}

#esportGameContainer .sportGameContainer_body {
    background: transparent;
    padding-top: 1.5rem;
}

#esportGameContainer .card_image_wrap {
    filter: drop-shadow(rgb(0, 0, 0) 2px 3px 3px);
}

#esportGameContainer .content_container {
    background: rgba(231, 231, 231, 0);
    color: #000;
}

#fishingGameContainer .fishingGameContainer_body {
    background: transparent;
    padding-top: 1.5rem;
}

#fishingGameContainer .card_image_wrap {
    filter: drop-shadow(rgb(0, 0, 0) 2px 3px 3px);
}

#fishingGameContainer .content_container {
    background: rgba(231, 231, 231, 0);
    color: #000;
}

#lotteryGameContainer .lotteryGameContainer_body {
    background: transparent;
    padding-top: 1.5rem;
}

#lotteryGameContainer .card_image_wrap {
    filter: drop-shadow(rgb(0, 0, 0) 2px 3px 3px);
}

#lotteryGameContainer .content_container {
    background: rgba(231, 231, 231, 0);
    color: #000;
}

#boardGameContainer .boardGameContainer_body {
    background: transparent;
    padding-top: 1.5rem;
}

#boardGameContainer .card_image_wrap {
    filter: drop-shadow(rgb(0, 0, 0) 2px 3px 3px);
}

#boardGameContainer .content_container {
    background: rgba(231, 231, 231, 0);
    color: #000;
}

#othersContainer .othersContainer_body {
    background: transparent;
    padding-top: 1.5rem;
}

#othersContainer .card_image_wrap {
    filter: drop-shadow(rgb(0, 0, 0) 2px 3px 3px);
}

#othersContainer .content_container {
    background: rgba(231, 231, 231, 0);
    color: #000;
}

.wallet_body {
    background: transparent
}

.wallet_body .pageTitle {
    background: transparent;
    color: #000;
    padding-top: 0;
}

.wallet_container_title {
    background: linear-gradient(to top, #33cc66, #ffff33);
    color: #000;
}

.walletBar {
    background: #fff;
    color: #000;
    filter: drop-shadow(2px 3px 3px rgb(231, 255, 0, .35));
    border: 1px solid #009d00
}

.walletBar_userInfo {
    color: #000;
}

.walletBar_userVerification {
    background: #f2f2f2;
}

.walletBar_selection.active {
    background: linear-gradient(to top, #33cc66, #ffff33);
    color: #000;
    border-radius: 5px
}

.wallet_container {
    border: 1px solid #009d00;
    border-radius: 10px 10px 10px 10px;
    filter: drop-shadow(2px 3px 3px rgb(231, 255, 0, .35));
}

.wallet_container_wrap {
    background: #fff;
    color: #000;
    border-radius: 0 0 10px 10px;
}

.wallet_depositMethod img+div,
.deposit_channel_row img+div {
    color: #a6a8ab;
}

.wallet_depositMethod img+div.active,
.deposit_channel_row img+div.active {
    color: #009d00;
}

.wallet_btnSubmit,
.wallet_gameWallet_btnAllIn,
.wallet_gameWallet_btnRefresh {
    color: #fff;
    border-radius: 5px 5px 5px 5px;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
}

.wallet_container .form-control:disabled {
    /* background: #808080; */
    color: rgba(0, 0, 0, 1);
}

.wallet_reminder_container {
    background: #f2f2f2;
    color: #000;
}

.wallet_reminder_title {
    color: red;
}

.table.wallet_history_table thead,
.myAccount_notification_header,
.myAccount_bankingDetails_tableHeader {
    background: #009d00;
    color: rgba(255, 255, 255, 1);
    border-top: 0px solid transparent;
    border-bottom: 0px solid transparent;
    border-left: 0px solid transparent;
    border-right: 0px solid transparent;
}

.table.wallet_history_table tbody,
.myAccount_notification_title_row,
.myAccount_bankingDetails_table {
    background: #f2f2f2;
    color: rgba(0, 0, 0, 1);
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
}

.wallet_btnAmount button {
    background: #f2f2f2;
    color: #808080;
    border: 1px solid #ccc;
    /* filter: opacity(.5); */
}

.wallet_btnAmount button.active {
    background: #009d00;
    color: #fff;
    /* border: 1px solid #009d00; */
    filter: opacity(1);
}

.myAccount_notification_content {
    background: #fff;
    color: #000;
}

.myAccount_changePasswordTab .myAccount_changePasswordTab_selection.active {
    background: red;
    color: #fff;
}

.myAccount_userInfo_profile label {
    color: #009d00;
}

.transactionTypeTab {
    background: linear-gradient(to top, #33cc66, #ffff33);
    border-radius: .5rem;
    padding: 0.5rem;
    color: rgba(0, 0, 0, .3);
}

.transactionTypeTab .transactionType_selection {
    background: transparent;
    color: rgba(0, 0, 0, .3);
    border-radius: .5rem;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
    margin-right: 1rem;
    padding: 0.75rem 1rem;
}

.transactionTypeTab .transactionType_selection.active {
    background: #fff;
    color: #000;
    border-radius: .5rem;
}

.wallet_body_container_m {
    padding: 0;
}

.wallet_container_m {
    border: 0;
    filter: none;
}

.wallet_container_m .wallet_container_wrap {
    background: transparent;
}

.wallet_container_m .wallet_reminder_container {
    background: transparent;
}

.walletBar_btnLogout {
    background: linear-gradient(to top, #a20000, #ff0000);
    color: #ffffff;
    border-radius: 5px 5px 5px 5px;
    border-top: 0px none;
    border-bottom: 0px none;
    border-left: 0px none;
    border-right: 0px none;
}

.depositBankImg img {
    border-radius: .375rem;
    border-top: 1px solid #009d00;
    border-bottom: 1px solid #009d00;
    border-left: 1px solid #009d00;
    border-right: 1px solid #009d00;
}

.deposit_transferContainer {
    border: 1px solid #009d00;
    background: #f2f2f2;
}

.btnSearch {
    background: linear-gradient(to top, #a20000, #ff0000);
    color: #ffffff;
}


.history_paginationButton button.active {
    background: #009d00;
    color: #ffffff;
}

.coupon_body {
    background: transparent;
    padding: 0 0 3rem;
}

#coupon_title {
    background: transparent;
    color: #000;
}

#coupon .filterTab {
    background: linear-gradient(to top, #33cc66, #ffff33);
    border-radius: 5px;
    margin-top: 2rem;
    padding: 0.5rem;
    color: rgba(0, 0, 0, .3);
}

#coupon .filterTab .filterTab_selection {
    padding: 0.5rem 1rem;
}

#coupon .filterTab .filterTab_selection.active {
    background: #fff;
    color: #000;
    border-radius: 5px;
}

.coupon_content_wrapper {
    color: #fff;
}

.coupon_body_container {
    color: rgba(0, 0, 0, 1);
}

.button_couponClaim {
    border-radius: 5px 5px 5px 5px;
    padding: 0.5rem;
}

.coupon_expireTime {
    color: red;
    margin-bottom: 1rem;
}

.coupon_tnc {
    color: #0d6efd;
}

.button_claimCoupon {
    border-radius: 5px;
    margin-top: .75rem;
    padding: .5rem;
}


.App,
.page {
    background: #fff
}


.walletBar_walletUserQrInfo {
    background: #f2f2f2;
}

.shareLink_button {
    background: linear-gradient(to top, #a20000, #ff0000);
    /* border: 1px solid #009d00; */
    border-radius: 5px;
    color: #fff;
}


.count_box p:first-of-type,
.count_container span {
    background: #1a1a1a;
    color: #fff
}

.contact_card.card span {
    color: #808080;
}

.walletBar_selection {
    color: #666666;
}

.myQRCode_body .shareQR_info {
    background: #e6e6e6;
    color: #000;
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, .25));
}


#myQRCode_title {
    background: transparent;
    color: #000;
}

.shareQR_shareMethodWrap {
    color: #000;
}

.shareQR_shareMethodWrap .shareQR_title {
    color: #009d00;
}

.feedback_info_wrap {
    padding: 1.25rem;
    padding-bottom: 0;
}

.feedback_username {
    font-size: 1.15rem;
    color: #808080;
    font-weight: 600;
}

.feedback_subject {
    font-size: 1.10rem;
    color: #009d00;
    font-weight: 600;
    padding-bottom: .5rem
}

.feedback_desc {
    font-size: .85rem;
    /* padding: 1rem;*/
    margin: 1rem 0;
}

.feedback_user_wrap {
    padding-bottom: .85rem
}

.card_feedback {
    filter: drop-shadow(2px 4px 3px rgba(0, 0, 0, .35));
    background: #e6e7e8;
    color: #000;
    border-radius: 5px;
}

.feedback_body {
    color: #fff;
    margin-bottom: 1.5rem;
}


.vip_joinNowContent_title {
    color: #000;

}

.vip_joinNowContent_subtitle {
    color: #000;
}

.vip_joinNowContent_button button {
    background: #009d00;
    color: #fff;
    border-radius: 5px;
}




.feedback_body .section_title {
    font-weight: 600;
}

.Section_HOT_GAMES_body .section_title {
    font-weight: 600;
}


#Section_HOT_GAMES .Section_HOT_GAMES_body {
    color: hsl(0, 0%, 100%);
}

#Section_HOT_GAMES .card_image_wrap {
    background: transparent;
    border-radius: 5px;
    filter: drop-shadow(rgb(231, 255, 0, .5)2px 2px 2px);
}

#Section_HOT_GAMES .content_container {
    background: transparent;
    color: #000;
    font-weight: 600;
}

.myFriend_body .btnSearch {
    background: linear-gradient(to top, #a20000, #ff0000);
}


.friendList_group_title {
    background: #f2f2f2;
    color: #000;
}

.friendList_group_item {
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #4d4d4d;
}

.friendList_group:not(:last-child) .friendList_group_item_container .friendList_group_item:last-of-type {
    border-bottom: none;
}

.friendList_info_phone,
.friendList_info_status,
.friendDetail_info_phone,
.friendDetail_info_status {
    color: #4d4d4d;
}

.friendRequest_tab {
    background: #e6e6e6;
    border-radius: 5px;
    color: #666666;
}


.friendRequest_tab .friendRequestTab_selection {
    border: 0;
    border-radius: 5px;
}


.friendRequest_tab .friendRequestTab_selection.active {
    background: #009d00;
    color: #fff;
}

.friendRequest_tab .friendRequestTab_selection:hover {
    border: 0;
    border-radius: 5px;
    /* color: #000; */
}

.friendDetail_group_item {

    border: 1px solid #4d4d4d;


}

.searchFriend_container {
    border: 1px solid #4d4d4d;
}


.main_game_container a {
    padding: 0 .5rem .25rem;
    color: #000;
}

.main_game_container a img {
    filter: drop-shadow(2px 4px 3px rgba(0, 0, 0, .35));
}

#Section_partners {
    padding-bottom: 3rem;
}

.dynamic_template_2 #Section_partners .section_header {
    padding: 0;
}


#Section_partners .templateSliderContainer {
    background: linear-gradient(to top, #33cc66, #ffff33);
    color: #000;
    border-radius: 10px;
    padding: 0 1rem;
}

#Section_partners .Section_partners_body {
    /* background: #fff; */
    color: #000;
}

#Section_partners .Section_partners_body .section_title {
    font-weight: 600;
}

#Section_partners .card_container {
    background: #fff;
    border-radius: 10px;
    margin: 1rem .5rem;
    padding: 0.5rem 1rem;
}

#Section_partners .card_image_wrap {
    background: transparent;
    border-radius: 10px 10px 10px 10px;
    filter: none;
}

#Section_partners .content_container {
    background: rgba(0, 0, 0, 0);
    color: #000;
    border-radius: 0px 0px 5px 5px;
}


#Section_LIVE_CASINO .card_image_wrap {
    filter: drop-shadow(rgb(155, 155, 155) 2px 3px 3px);
}

.walletBar_point {
    background: #e3cee5;
    /* color:#fff; */
}

.realNameVerification_container .realNameVerification_viewSample_button {
    border-radius: 5px;
    background: linear-gradient(to top, #a20000, #ff0000);
}

.myAccount_userInfo_profile .verifyNow_button {
    border-radius: 5px;
    background: linear-gradient(to top, #a20000, #ff0000);

}

.rankingList_body {
    background: transparent;
    color: #000;
    padding: 1.5rem 0 1.5rem;
}

.rankingList_table_container {
    background: #f2f2f2;
    padding: 1.75rem;
    border-radius: 10px;
}


.rankingList_table {
    background: transparent;
    border-radius: 15px 15px 15px 15px;
    filter: drop-shadow(rgb(0, 0, 0, .25) 2px 3px 3px);
}

.rankingList_table_header {
    background: linear-gradient(to top, #33cc66, #ffff33);
    color: #000;
    font-weight: normal;
    border-radius: 10px 10px 10px 10px;
    margin: 0;
    padding: 1rem;
}

.rankingList_table_container .rankingList_table_header+.rankingList_table_body {
    padding: 0;
}

.rankingList_table_row {
    background: #fff;
    color: #000;
    margin-top: 1.25rem;
    border-radius: 10px;
    /* border-bottom: 1px solid #fff; */
    /* margin-left: 1rem;
    margin-right: 1rem; */
}

.rankingList_username {
    color: #000;
    font-weight: bold;
}

.rankingList_info {
    color: red;
}

.rankingList_amount {
    color: #000;
    font-weight: bold;
}

.rankingList_m_table .rankingList_tableTab {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 10px 10px 10px 10px;
    filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, .15));
    padding: 0.65rem;
}

.rankingList_tableTab .rankingList_table_selection {
    background: transparent;
    color: #000;
    font-weight: 600;
    border-radius: 10px;
    border: 0;
}

.rankingList_tableTab .rankingList_table_selection.active {
    background: linear-gradient(to top, #33cc66, #ffff33);
    color: #000;
    border: 0;
    border-radius: 10px;
}

.rankingList_m_table {
    background: #f2f2f2;
    border-radius: 10px;
    padding: 1rem;
}

.rankingList_m_table .rankingList_table {
    background: transparent;
    border-radius: 10px 10px 10px 10px;
    filter: none;
    padding: 0;
}

.rankingList_m_table .rankingList_table_body {
    margin-top: .65rem;
}

.rankingList_m_table .rankingList_table_row {
    background: #fff;
    border-radius: 10px;
    padding: 1rem;
    border: 1px solid #ccc;
    filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, .15));
}


.bankingDetails_row_img {
    border: 1px solid #009d00;
    border-radius: 5px;
}

.myAccount_bankingDetails_row {
    border: 1px solid #ccc;
}




#vipPage {
    background: #000;
}


.vipTab .vipTab_selection {
    color: #ccc;
    border-radius: 10px;
}

.vipTab .vipTab_selection.active {
    background: linear-gradient(to top, #33cc66, #ffff33);
    color: #000;
}

/* .vip_container {margin: 0 10rem;} */

.vip_container_m {
    margin: 0;
}

.vipTab {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

#vip_title {
    background: transparent;
    color: #fff;
}

/* 
#vip_content,
#vip_content_m {
    background: #fff;
    margin-bottom: 2rem;

}

.vip_container {
    margin-top: 0;
} */


.friendRequest_tab .friendRequestTab_selection.active:hover {
    color: #fff;
}


.myAccount_bankingDetails_table tr td img {
    border: 1px solid #009d00;
    border-radius: .25rem;
}

.card_wrap .game_new_icon{
    top: -.5rem;
    right: -.65rem;
}
